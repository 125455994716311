import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// font awesome
import { dom } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import * as fas from '@fortawesome/free-solid-svg-icons'
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
library.add(
  fas.faMobileAlt, // mobile
  fas.faDesktop, // desktop
  fas.faVideo, // cctv
  fas.faPlug, // integrations
  fas.faCheckCircle, // check
)
dom.watch()


// buefy
import Buefy from 'buefy'
import './style/main.scss'
Vue.use(Buefy)


// firestore
import firebase from 'firebase/app'
import 'firebase/firestore'
firebase.initializeApp({
  apiKey: "AIzaSyDGsw0HcA0UxuNR5db4uIKiVKROhaiDWi0",
  authDomain: "vapps-online.firebaseapp.com",
  databaseURL: "https://vapps-online.firebaseio.com",
  projectId: "vapps-online",
  storageBucket: "vapps-online.appspot.com",
  messagingSenderId: "315257772533",
  appId: "1:315257772533:web:64b9bb254c52c8e8b628bc"
})
Vue.prototype.$firestore = firebase.firestore()

// our components
import WizardSteps from './components/reusable/WizardSteps.vue'
import Heading from './components/reusable/Heading.vue'
Vue.component('wizard-steps', WizardSteps)
Vue.component('vapps-heading', Heading)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
