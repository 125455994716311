<template>
  <div class="columns">
    <div class="column has-text-left">
      <b-button @click="$emit('previous')" v-if="!disablePrevious">Previous</b-button>
    </div>
    <div class="column has-text-centered mt-4">
      <slot/>
    </div>
    <div class="column">
      <div class="buttons has-addons is-justify-content-flex-end">
        <slot name="beforeNext"/>
        <b-button v-if="is_edit" @click="$emit('save')">Save partial progress</b-button>
        <b-button v-if="!disableNext" @click="$emit('next')" type="is-link" :disabled="invalid">Save and Proceed</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['previous', 'save', 'next'],
  props: {
    invalid: Boolean,
    disableNext: Boolean,
    disablePrevious: Boolean
  },
  computed: {
    is_edit () {
      return this.$route.params.id
    }
  }
}
</script>