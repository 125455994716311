<template>
  <article class="media block mb-6">
    <figure class="media-left">
      <p class="image is-64x64">
        <img src="../../assets/logo.png">
      </p>
    </figure>
    <div class="media-content">
      <h1 class="title">{{ title }}</h1>
      <h2 v-if="subtitle" class="subtitle">{{ subtitle }}</h2>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String
  }
}
</script>